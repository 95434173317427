import React from 'react';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { useAdminPanelContext } from '../adminPanel/adminPanelContext';
import { Container } from './logo.styles';

export interface LogoutProps {
  className?: string;
}

export const Logout = ({ className }: LogoutProps) => {
  const { logout } = useAdminPanelContext();

  return (
    <Container onClick={logout} className={className}>
      {
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M12 8L16 12M16 12L12 16M16 12H3M3.33782 7C5.06687 4.01099 8.29859 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C8.29859 22 5.06687 19.989 3.33782 17"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
    </Container>
  );
};
