import { configureStore as toolkitConfigureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { promiseMiddleware } from '../shared/utils/reduxSagaPromise';
import createReducer from './reducers';
import rootSaga from './sagas';

export default function configureAppStore(initialState = {}) {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [promiseMiddleware, sagaMiddleware];

  const store = toolkitConfigureStore({
    reducer: createReducer(),
    preloadedState: initialState,
    middleware: getDefaultMiddleware().concat(middlewares),
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
