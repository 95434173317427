import React from 'react';
import { useLocation } from 'react-router-dom';
import { ActiveVisualization, Container, IconVisualization } from './logo.styles';

export interface VisualizationProps {
  className?: string;
}

export const Visualization = ({ className }: VisualizationProps) => {
  const location = useLocation();

  return (
    <Container className={className}>
      {location.pathname === '/visualisation' ? <ActiveVisualization /> : <IconVisualization />}
    </Container>
  );
};
