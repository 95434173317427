import styled from 'styled-components';
import { ReactComponent as FullLogoSVG } from '../../../images/icons/logo-full.svg';
import { ReactComponent as IconLogoSVG } from '../../../images/icons/logo-icon.svg';
import { ReactComponent as FullSearchSVG, ReactComponent as IconSearchSVG } from '../../../images/icons/aip-search.svg';
import { ReactComponent as FullChatSVG, ReactComponent as IconChatSVG } from '../../../images/icons/aip-chat.svg';
import {
  ReactComponent as FullVisualizationSVG,
  ReactComponent as IconVisualizationSVG,
} from '../../../images/icons/aip-visualization.svg';
import { ReactComponent as FullNewsSVG, ReactComponent as IconNewsSVG } from '../../../images/icons/aip-news.svg';
import { ReactComponent as FullUsersSVG, ReactComponent as IconUsersSVG } from '../../../images/icons/aip-users.svg';
import {
  ReactComponent as FullProfileSVG,
  ReactComponent as IconProfileSVG,
} from '../../../images/icons/aip-profile.svg';
import { ReactComponent as FullRadarSVG, ReactComponent as IconRadarSVG } from '../../../images/icons/aip-radar.svg';

export const Container = styled.div`
  height: 100%;
`;

export const FullLogo = styled(FullLogoSVG)`
  height: 100%;
`;

export const Iconlogo = styled(IconLogoSVG)`
  height: 100%;
`;

export const ActiveSearch = styled(FullSearchSVG)`
  filter: invert(70%) sepia(31%) saturate(1340%) hue-rotate(129deg) brightness(95%) contrast(94%);
`;
export const IconSearch = styled(IconSearchSVG)`
  height: 100%;
`;

export const ActiveChat = styled(FullChatSVG)`
  filter: invert(131%) sepia(63%) saturate(1416%) hue-rotate(130deg) brightness(75%) contrast(104%);
`;
export const IconChat = styled(IconChatSVG)`
  height: 100%;
`;

export const ActiveRadar = styled(FullRadarSVG)`
  filter: invert(131%) sepia(63%) saturate(1416%) hue-rotate(130deg) brightness(75%) contrast(104%);
`;
export const IconRadar = styled(IconRadarSVG)`
  height: 100%;
`;
export const ActiveVisualization = styled(FullVisualizationSVG)`
  filter: invert(131%) sepia(63%) saturate(1416%) hue-rotate(130deg) brightness(75%) contrast(104%);
`;
export const IconVisualization = styled(IconVisualizationSVG)`
  height: 100%;
`;
export const IconNews = styled(IconNewsSVG)`
  height: 100%;
`;
export const ActiveNews = styled(FullNewsSVG)`
  filter: invert(131%) sepia(63%) saturate(1416%) hue-rotate(130deg) brightness(75%) contrast(104%);
`;
export const IconUsers = styled(IconUsersSVG)`
  height: 100%;
`;
export const ActiveUsers = styled(FullUsersSVG)`
  filter: invert(131%) sepia(63%) saturate(1416%) hue-rotate(130deg) brightness(75%) contrast(104%);
`;
export const IconProfile = styled(IconProfileSVG)`
  height: 100%;
`;
export const ActiveProfile = styled(FullProfileSVG)`
  filter: invert(131%) sepia(63%) saturate(1416%) hue-rotate(130deg) brightness(75%) contrast(104%);
`;
