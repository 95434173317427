import React from 'react';
import { useLocation } from 'react-router-dom';
import { ActiveSearch, Container, IconSearch } from './logo.styles';

export interface SearchProps {
  className?: string;
}

export const Search = ({ className }: SearchProps) => {
  const location = useLocation();
  return (
    <Container className={className}>{location.pathname === '/search' ? <ActiveSearch /> : <IconSearch />}</Container>
  );
};
