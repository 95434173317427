// context/LanguageContext.tsx
import { Language } from '@amcharts/amcharts5/.internal/core/util/Language';
import React, { createContext, useState, useContext, ReactNode } from 'react';

interface LanguageContextType {
  locale: string;
  setLocale: (lang: string) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

interface LanguageProviderProps {
  children: ReactNode;
}

export const LanguageProvider = ({ children }:  LanguageProviderProps) => {
  const [locale, setLang] = useState<string>('en'); // default language

  function setLocale(lang: string) {
    console.log('setLocale', lang);
    setLang(lang);
  }
  return (
    <LanguageContext.Provider value={{ locale, setLocale }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
