// @ts-nocheck
import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/joy/FormControl';
import Textarea from '@mui/joy/Textarea';
import sendIcon from '../../images/sendArrow.svg';
import FileUpload from './fileUpload';
import AudioRecorder from './audioRecorder';
import SelectModel from './SelectModel';
import LanguageButton from './languageDropDown';
import { useCallback, useEffect, useRef, useState } from 'react';
import chatMessages from '../chat/components/chat.messages';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import axiosBackendApi from '../../shared/services/api/axiosBackendInstance';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import FileList from './FileList'; // Import the separate FileList component
import ButtonSummary from './ButtonSummary';
import {
  clearSession,
  createSession as createSessionStore,
  fetchSessionById,
  newLog,
  newQuestion,
  setLogId,
} from '../../store/actions/sessionsActions';
import { ToastRenderer } from '../../shared/components/toast';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDropzone } from 'react-dropzone';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import {
  Alert,
  CircularProgress,
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import SearchLoadingImg from '../../images/loading.gif';
import { useToast } from '../../shared/components/toast';

const CHAT_URL = process.env.REACT_APP_CHAT_API;
const SEARCH_URL = process.env.REACT_APP_WEB_API;
let handleOpenModal = false;
let token = '';

export default function ExampleTextareaComment({ messages }) {
  const toast = useToast()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const questionparam = queryParams.get('question');

  const [fileErrors, setFileErrors] = useState({});
  const [anchorElRemoveAll, setAnchorElRemoveAll] = React.useState<HTMLButtonElement | null>(null);
  const [italic, setItalic] = React.useState(false);
  const [fontWeight, setFontWeight] = React.useState('normal');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [chatInputDisabled, setChatInputDisabled] = useState(false);
  const [question, setQuestion] = useState(type === 'live' ? questionparam : '');

  const [model, setModel] = useState(type === 'live' ? 'chatRAGOnline' : 'chatLLM');

  const [inputDisabled, setInputDisabled] = useState(false);
  const [controller, setController] = useState(new AbortController());
  const [streaming, setStreaming] = useState(false);
  const [signal, setSignal] = useState(controller.signal);
  const [showIcons, setShowIcons] = useState(false);
  const [showStopResponse, setShowStopResponse] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [answer, setAnswer] = useState('');
  const [chatting, setChatting] = useState(false);
  const intl = useIntl();
  const [isInitial, setIsInitial] = useState(true);
  const [userId, setUserId] = useState();
  const [openRemoveAll, setOpenRemoveAll] = useState(false);
  const [modalFileName, setModalFileName] = React.useState('');
  const [openPreviewModal, setOpenPreviewModal] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogDeleteFile, setOpenDialogDeleteFile] = React.useState(false);
  const [dialogFileToDelete, setDialogFileToDelete] = React.useState({ name: null, index: 0 });
  const [dialogFileToDeleteFile, setDialogFileToDeleteFile] = React.useState({ name: null, index: 0 });
  const [selectedVoiceLang, setSelectedVoiceLang] = useState('Auto');

  const handleOpenDialog = (fileName, index) => {
    setDialogFileToDelete({ name: fileName, index });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCloseDialogFile = () => {
    setDialogFileToDelete(false);
  };

  const confirmDelete = () => {
    const { name, index } = dialogFileToDelete;
    if (name) {
      setFileToDelete({ name: null, i: 0 });
      removeFiles();
    } else {
      // Handle bulk delete if needed
    }
    handleCloseDialog();
  };
  const confirmDeleteFile = () => {
    const { name, index } = dialogFileToDeleteFile;
    if (name) {
      setFileToDeleteFile({ name: null, i: 0 });
      // removeFiles();
    } else {
      // Handle bulk delete if needed
    }
    handleCloseDialogFile();
  };

  const handleOpenPreviewModal = () => setOpenPreviewModal(true);
  const handleClosePreviewModal = () => setOpenPreviewModal(false);
  const handleCloseRemoveAll = () => {
    setAnchorElRemoveAll(null);
    setOpenRemoveAll(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const options = [
    {
      value: 'chatLLM',
      selected: true,
      label: <span className="chat-type chat-color">{intl.formatMessage(chatMessages.chatLLM)}</span>,
    },
    {
      value: 'chatRAGOnline',
      label: <span className="chat-type chat-color">{intl.formatMessage(chatMessages.chatRAGOnline)}</span>,
    },
    {
      value: 'chatRAGOffline',
      label: <span className="chat-type chat-color">{intl.formatMessage(chatMessages.chatRAGOffline)}</span>,
    },
    {
      value: 'chatBrief7b',
      label: <span className="chat-type chat-color">{intl.formatMessage(chatMessages.chatBrief7b)}</span>,
    },
    {
      value: 'chatBrief13b',
      label: <span className="chat-type chat-color">{intl.formatMessage(chatMessages.chatBrief13b)}</span>,
    },
    {
      value: 'chatFiles',
      label: <span className="chat-type chat-color">{intl.formatMessage(chatMessages.chatFiles)}</span>,
    },
  ];
  const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'ar', label: 'Arabic' },
    {
      value: 'fr',
      label: 'French',
    },
    { value: 'es', label: 'Spanish' }, // Add more languages as needed
  ];
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    token = user.token;
    setUserId(user.id);
    if (id != undefined) {
      setSessionId(id);
      setIsInitial(false);
    }
    // getFiles();
    if (id === undefined) {
      setAnswer('');
      setChatting(false);
      setIsInitial(true);
    }
    return () => { };
  }, [id]);

  useEffect(() => {
    if (chatting) {
      handleFetchData();
    }
  }, [chatting]);

  useEffect(() => {
    if (answer != '') {
      storeLog(sessionId);
    }
  }, [answer]);
  const createSession = async () => {
    try {
      let data = {
        title: question.substr(0, 50),
      };
      const session = await dispatch(createSessionStore(data)).unwrap();
      setIsInitial(false);
      setSessionId(session.id);
      return session;
    } catch (e) {
    } finally {
    }
    return id;
  };

  const send = async () => {
    if (question && question.trim() != '') {
      dispatch(newQuestion(question));
      if (isInitial) {
        await createSession();
      }
      setChatting(true);
    }
  };

  const handleFetchData = () => {
    if (model == chatMessages.chatLLM.id) {
      fetchData('/chat');
    } else if (model == chatMessages.chatRAGOnline.id) {
      fetchData('/rag_online');
    } else if (model == chatMessages.chatRAGOffline.id) {
      fetchData('/rag_mongodb');
    } else if (model == chatMessages.chatBrief7b.id) {
      fetchData('/llama_summarize_7b');
    } else if (model == chatMessages.chatBrief13b.id) {
      fetchData('/llama_summarize_13b');
    } else if (model == chatMessages.chatFiles.id) {
      fetchData('/rag_pdf');
    }
  };
  function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box className="file-upload-progress" sx={{}}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" color="success" {...props} />
        </Box>
        {props.value < 100 ? (
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
          </Box>
        ) : (
          ''
        )}
      </Box>
    );
  }

  const fetchData = async (chatUrl) => {
    setChatting(true);
    setInputDisabled(true);
    let payload = {};
    const localData = localStorage.getItem('user');
    const userData = JSON.parse(localData);
    payload = { user_id: userData.id, query: question, session_id: sessionId };
    console.log(CHAT_URL, 'here');
    let stopChunks = false;
    const url = CHAT_URL + chatUrl;
    setStreaming(true);

    try {
      const myResponse = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(payload),
        signal: signal,
      });
      if (!myResponse.ok) {
        console.log('here error');
        const errorData = await myResponse.json();
        // handleReceiveMessage(errorData.errors.error_message, 'error');
      }

      const reader = myResponse.body.getReader();
      // setLoading(false);
      let chunks = '';
      setAnswer('');
      reader.read().then(function processText({ done, value }) {
        setShowStopResponse(true);
        let chunk = new TextDecoder('utf-8').decode(value);
        if (done) {
          // setShowStopResponse(false);
          setShowIcons(true);
          setAnswer(chunks);
          setInputDisabled(false);
          setChatting(false);
          // startSession(chunks);
          return;
        }
        if (stopChunks) {
          // console.log('stop');
          setShowIcons(true);
          setAnswer(chunks);
          setChatting(false);
          // setShowStopResponse(false);
          setInputDisabled(false);
          // startSession(chunks);
          return;
        }
        if (chunk === '### SEARCHING' || chunk === '### PROCESSING') {
          chunks = chunk;
        } else {
          if (chunks === '### PROCESSING') {
            chunks = chunk;
          } else {
            chunks += chunk.replace(/```/g, '\n\n ``` \n\n');
            chunks = chunks.replace(/### SEARCHING/g, '');
            chunks = chunks.replace(/### PROCESSING/g, '');
          }
        }
        dispatch(newLog({ chunks, model }));
        // handleReceiveMessage(chunks, '');
        return reader.read().then(processText);
      });
    } catch (error) {
    } finally {
    }
  };
  const storeLog = async (session_id) => {
    let data = {
      question: question,
      answer: answer,
      session_id: session_id,
      model,
    };
    axiosBackendApi.post(SEARCH_URL + '/user/session/log', data).then((response) => {
      if (response.status == 200) {
        const log = response.data.session;
        dispatch(setLogId({ log }));
        history.push(`/chat/${session_id}`);
      }
    });
    setQuestion('');
  };
  const onEnterPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && !inputDisabled) {
      send();
    }
  };
  const formData = (i) => {
    let formData = new FormData();

    formData.set('part', files[i].parts - files[i].chunks.length);
    formData.set('is_last', files[i].chunks.length === 1);
    formData.set('filename', files[i].name);
    formData.set('file', files[i].chunks[0], `${files[i].name}.part`);

    return formData;
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState(false);
  const [errorMsg, setErrorMsg] = React.useState('error');
  const [errorAlert, setErrorAlert] = React.useState(false);
  const handleCloseError = () => setErrorAlert(false);

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleCloseError}>
        UNDO
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseError}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const closeErrorAlert = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorAlert(false);
  };
  const [isLoading, setIsLoading] = useState(true);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'file/pdf': ['.pdf'],
    },
    maxFiles: 1,
    multiple: false,
    onDrop: (files) => {
      console.log(files);
      upload(files);
    },
  });
  const upload = async (_files) => {
    const fileSizeMB = _files[0]?.size / 1024 / 1024;
    if (fileSizeMB < 100 && !files.some((obj) => obj.name === _files[0].name)) {
      setFileError(false);
      handleOpenModal = false;
      let i = files.length;
      // add file
      files.push({
        file: _files[0],
        name: _files[0].path,
        parts: 0,
        chunks: [],
        uploaded: 0,
        toc: null,
        tocError: false,
      });
      // creating chunks
      let size = 512000,
        chunks = Math.ceil(files[i].file.size / size);
      files[i].parts = chunks;
      for (let j = 0; j < chunks; j++) {
        files[i].chunks.push(
          files[i].file.slice(j * size, Math.min(j * size + size, files[i].file.size), files[i].file.type)
        );
      }
      scrollToBottom();
      // upload
      sendUploadedFiles(i);
    } else {
      scrollToBottom();
      // setFileError(true);
    }
  };

  const getFiles = async () => {
    setIsLoading(true); // Start loading
    const localData = localStorage.getItem('user');
    const userData = JSON.parse(localData);
    try {
      const response = await axios.post(
        `${SEARCH_URL}/get_file_names`,
        { user_id: userData.id },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(response?.data);
      const newFiles = response?.data?.map((file) => ({
        file: {},
        name: file.file_name,
        parts: 0,
        chunks: [],
        uploaded: 0,
        toc: '',
        url: file.file_url,
      }));
      setFiles(newFiles);
    } catch (error) {
      console.error('Failed to fetch files:', error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const [fileToDelete, setFileToDelete] = useState({
    name: null,
    i: 0,
  });
  const [fileToDeleteFile, setFileToDeleteFile] = useState({
    name: null,
    i: 0,
  });
  const handleDeleteFile = (fileName, index) => {
    setFileToDelete({ name: fileName, i: index });
  };
  const removeFiles = (name) => {
    setFileError(false);
    handleClose();
    handleCloseRemoveAll();
    // setFiles([])
    let data = {};
    if (name) {
      data = {
        user_id: userId,
        file_name: name,
      };
    } else {
      data = {
        user_id: userId,
      };
    }
    axios
      .post(
        CHAT_URL + '/delete_user_files',
        data,
        // {
        //   files: filename ? [filename] : [],
        // },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then((response) => {
        getFiles();
      })
      .catch((error) => {
        getFiles();
      });
  };

  const sendUploadedFiles = (i) => {
    if (files[i].chunks.length < 1) {
      return;
    }

    if (files[i].uploaded == files[i].parts && files[i].name != '') {
      return;
    }

    // SEARCH_URL
    axios
      .post(SEARCH_URL + '/upload-chunks', formData(i), {
        headers: {
          'Content-Type': 'application/octet-stream',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          files[i].uploaded += 1;
          files[i].name = response?.data?.filename || files[i].name;

          files[i].chunks.shift();
          if (files[i].chunks.length > 0) {
            sendUploadedFiles(i);
          }

          if (response?.data?.completed) {
            setFiles((prevArr) => {
              const result = [...prevArr];
              result[i].url = response?.data?.file_url;
              return result;
            });
            // send to summarize TOC // files[i].toc
            summary(files[i].name, i);
          }
        }
      })
      .catch((error) => {
        if (error.response?.status == 400) {
          setErrorMsg(error.response?.data?.errors?.error_message || 'Failed');
          setErrorAlert(true);
        }
        files.splice(i, 1);
      });
  };
  const [tocError, setTocError] = useState(false); // State to handle TOC error

  // const summary = async (fileName, i) => {
  //   try {
  //     const response = await axios.post(
  //       SEARCH_URL + '/toc',
  //       {
  //         user_d: userId,
  //         file_name: fileName,
  //       },
  //       {
  //         headers: {
  //           Authorization: 'Bearer ' + token,
  //         },
  //       }
  //     );
  //     if (response.status === 200) {
  //       setFiles((prevArr) => {
  //         const result = [...prevArr];
  //         result[i].toc = response.data.summary_url;
  //         return result;
  //       });
  //       if (handleOpenModal) {
  //         setFileUrl(files[i].toc);
  //         setModalFileName(fileName);
  //         setTimeout(() => {
  //           handleOpenPreviewModal();
  //         }, 200);
  //       }
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     setFileErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [i]: true,
  //     }));
  //     setFiles((prevArr) => {
  //       const result = [...prevArr];
  //       result[i].toc = '';
  //       return result;
  //     });
  //   }
  // };

  const summary = async (fileName, i) => {
    const MAX_TIMEOUT = 5000; // 5 seconds timeout
    const RETRY_DELAY = 5000; // 5 seconds delay before retry

    try {
      const source = axios.CancelToken.source(); // Create a cancel token

      const timeoutId = setTimeout(() => {
        source.cancel(`Request timed out after ${MAX_TIMEOUT / 1000} seconds`);
      }, MAX_TIMEOUT);

      const response = await axios.post(
        SEARCH_URL + '/toc',
        {
          user_id: userId,
          file_name: fileName,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
          cancelToken: source.token,
        }
      );

      clearTimeout(timeoutId); // Clear the timeout if the request completes in time

      if (response.status === 200) {
        setFiles((prevArr) => {
          const result = [...prevArr];
          result[i].toc = response.data.summary_url;
          return result;
        });
        if (handleOpenModal) {
          setFileUrl(files[i].toc);
          setModalFileName(fileName);
          setTimeout(() => {
            handleOpenPreviewModal();
          }, 200);
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.warn('Request canceled:', error.message);
        // Wait for 5 seconds before retrying
        setTimeout(() => {
          summary(); // Retry the request
        }, RETRY_DELAY);

      } else {
        console.error('Request failed:', error.message);
        toast.error("Request failed:")
      }

      setFileErrors((prevErrors) => ({
        ...prevErrors,
        [i]: true,
      }));

      setFiles((prevArr) => {
        const result = [...prevArr];
        if(result[i] !== undefined) {
          result[i].toc = '';
        }
        return result;
      });

      // Retry after delay if it was a timeout
      if (axios.isCancel(error)) {
        setTimeout(() => {
          summary(fileName, i); // Retry the request
        }, RETRY_DELAY);
      }
    }
  };







  const handleRetry = (fileName, index) => {
    setFileErrors((prevErrors) => ({
      ...prevErrors,
      [index]: false,
    }));
    summary(fileName, index);
  };

  // const summary = async (fileName, i) => {
  //   await axios
  //     .post(
  //       SEARCH_URL + '/toc',
  //       {
  //         user_id: userId,
  //         file_name: fileName,
  //       },
  //       {
  //         headers: {
  //           Authorization: 'Bearer ' + token,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       if (response.status == 200) {
  //         setFiles((prevArr) => {
  //           const result = [...prevArr];
  //           result[i].toc = response?.data?.summary_url;
  //           return result;
  //         });
  //       }
  //       if (handleOpenModal) {
  //         setFileUrl(files[i].toc);
  //         setModalFileName(fileName);
  //         setTimeout(() => {
  //           handleOpenPreviewModal();
  //         }, 200);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       if (error.response?.status == 400) {
  //         setErrorMsg(error.response?.data?.errors?.error_message || 'Failed');
  //         setErrorAlert(true);
  //       }
  //       // to remove loading and enable user to fetch api one more time
  //       //  if(files[i].toc){
  //       setFiles((prevArr) => {
  //         const result = [...prevArr];
  //         result[i].toc = '';
  //         return result;
  //       });
  //       // }
  //     });
  // };

  const filesRef = useRef(null);
  const [fileUrl, setFileUrl] = React.useState('');
  const scrollToBottom = () => {
    filesRef?.current?.addEventListener('DOMNodeInserted', (e) => {
      e.currentTarget.scroll({
        top: e.currentTarget.scrollHeight,
        behavior: 'smooth',
      });
    });
  };
  const handleClickRemoveAll = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElRemoveAll(event.currentTarget);
    setOpenDialog(true);
  };
  const openSummary = (fileName, i) => {
    handleOpenModal = true;
    if (files[i].toc == '') {
      setFiles((prevArr) => {
        const result = [...prevArr];
        result[i].toc = null;
        return result;
      });
      summary(fileName, i); // if success open modal;

      // setTimeout(() => {
      //   if(handleOpenModal) {
      //     setFileUrl(files[i].toc)
      //     setModalFileName(fileName)
      //     setTimeout(() => {
      //       handleOpenPreviewModal()
      //     }, 200);
      //   }
      // }, 2000);
    } else {
      setFileUrl(files[i].toc);
      setModalFileName(fileName);
      setTimeout(() => {
        handleOpenPreviewModal();
      }, 200);
    }
  };
  const openFile = (fileName, url) => {
    if (url !== '') {
      setFileUrl(url);
      setModalFileName(fileName);
      setTimeout(() => {
        handleOpenPreviewModal();
      }, 200);
    } else {
      setErrorMsg("File url doesn't exist");
      setErrorAlert(true);
    }
  };
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const drawers = () => {
    return (
      <div className={drawerOpen ? 'drawer-open drawer' : 'drawer'} style={{ zIndex: 999 }}>
        {/* Upload / Dropzone area */}
        <div className="d-flex align-items-center justify-content-between">
          <Typography variant="h4" className="drawer-title">
            {/*{messages.UploadFiles}*/}
            UploadFiles
          </Typography>

          <CloseIcon onClick={() => setDrawerOpen(false)} className="cursor-pointer close-files" />
        </div>
        <div {...getRootProps()} className="upload-block mb-2">
          <input {...getInputProps()} />
          <CloudUploadIcon className="color-main" />
          <p mt={1}>
            {/*{messages.DragAndDrop}*/}
            Drag and drop files here or click to browse.
          </p>
        </div>
        {/* Files list */}
        <ul className="files-list scroll" ref={filesRef}>
          {isLoading && (
            <div className="data-loading">
              <img src={SearchLoadingImg} className="loading-img" />
            </div>
          )}
          {files.length ? (
            <div className="d-flex justify-content-end mb-2 ">
              {/*<DeleteForeverIcon  className="remove-files"/>*/}
              {/*<Typography variant="body1" color="error" className="remove-files" onClick={removeFiles}>*/}
              {/*  Remove all*/}
              {/*</Typography>*/}
              <div>
                <div>
                  <Button
                    variant="body1"
                    color="error"
                    className="remove-files"
                    sx={{ color: 'rgba(135, 142, 165, 0.75)' }}
                    onClick={(event) => {
                      setFileToDelete({ name: null, i: 0 });
                      handleClickRemoveAll(event);
                    }}
                  >
                    {messages.RemoveAll}
                  </Button>
                </div>
                <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="delete-dialog-title"
                  aria-describedby="delete-dialog-description"
                >
                  <DialogTitle id="delete-dialog-title">{'Confirm File Deletion'}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                      Are you sure you want to delete this file?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleCloseDialog}
                      sx={{
                        backgroundColor: '#9c9f9f',
                        color: '#fff !important',
                        '&:hover': {
                          backgroundColor: '#f00',
                        },
                      }}
                      autoFocus
                    >
                      <span className={'btn-text'}>Cancel</span>
                    </Button>
                    <Button
                      onClick={() => {
                        setFileToDelete({ name: null, i: 0 });
                        removeFiles();
                        handleCloseDialog();
                      }}
                      sx={{
                        backgroundColor: '#085950',
                        color: '#fff !important',
                        '&:hover': {
                          backgroundColor: '#f00',
                        },
                      }}
                      autoFocus
                    >
                      <span className={'btn-text'}>Delete</span>
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          ) : (
            !isLoading && (
              <Typography variant="body2" color="text.secondary" sx={{ color: '#eee' }}>
                No files to display.
              </Typography>
            )
          )}

          {files.map((file, i) => (
            <li key={i} className='flex flex-col space-y-4 gap-3'>
              <div className="d-flex align-items-center w-100">
                <span>{file.name}</span>
              </div>
              {file.uploaded === file.parts ? (
                <div className="d-flex file-btns space-x-4">
                  <div>
                    <Button
                      aria-describedby={id}
                      variant=""
                      title={messages.deleteFile}
                      onClick={() => {
                        console.log({ file });
                        setFileToDelete({ name: file.name, i });
                        setOpenDialogDeleteFile(true);
                      }}
                      className="text-link Download"
                    >
                      <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M13.3333 5.00008V4.33341C13.3333 3.39999 13.3333 2.93328 13.1517 2.57676C12.9919 2.26316 12.7369 2.00819 12.4233 1.8484C12.0668 1.66675 11.6001 1.66675 10.6667 1.66675H9.33333C8.39991 1.66675 7.9332 1.66675 7.57668 1.8484C7.26308 2.00819 7.00811 2.26316 6.84832 2.57676C6.66667 2.93328 6.66667 3.39999 6.66667 4.33341V5.00008M8.33333 9.58342V13.7501M11.6667 9.58342V13.7501M2.5 5.00008H17.5M15.8333 5.00008V14.3334C15.8333 15.7335 15.8333 16.4336 15.5608 16.9684C15.3212 17.4388 14.9387 17.8212 14.4683 18.0609C13.9335 18.3334 13.2335 18.3334 11.8333 18.3334H8.16667C6.76654 18.3334 6.06647 18.3334 5.53169 18.0609C5.06129 17.8212 4.67883 17.4388 4.43915 16.9684C4.16667 16.4336 4.16667 15.7335 4.16667 14.3334V5.00008"
                          stroke="#E85050"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Button>
                  </div>
                  <Dialog
                    open={openDialogDeleteFile}
                    onClose={() => setOpenDialogDeleteFile(false)}
                    aria-labelledby="delete-dialog-title"
                    aria-describedby="delete-dialog-description"
                  >
                    <DialogTitle id="delete-dialog-title">{'Confirm File Deletion'}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="delete-dialog-description">
                        Are you sure you want to delete this file?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => setOpenDialogDeleteFile(false)}
                        sx={{
                          backgroundColor: '#9c9f9f',
                          color: '#fff !important',
                          '&:hover': {
                            backgroundColor: '#f00',
                          },
                        }}
                        autoFocus
                      >
                        <span className={'btn-text'}>Cancel</span>
                      </Button>
                      <Button
                        onClick={() => {
                          setFileToDeleteFile({ name: null, i: 0 });
                          removeFiles(file.name);
                          setOpenDialogDeleteFile(false);
                        }}
                        sx={{
                          backgroundColor: '#085950',
                          color: '#fff !important',
                          '&:hover': {
                            backgroundColor: '#f00',
                          },
                        }}
                        autoFocus
                      >
                        <span className={'btn-text'}>Delete</span>
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Button
                    aria-describedby={id}
                    variant=""
                    className="text-link Download"
                    title={messages.showFile}
                    onClick={() => openFile(file.name, file.url)}
                  >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.60469 10.1233C7.60469 8.8363 8.59831 7.79301 9.82399 7.79301C11.0497 7.79301 12.0433 8.8363 12.0433 10.1233C12.0433 11.4102 11.0497 12.4535 9.82399 12.4535C8.59831 12.4535 7.60469 11.4102 7.60469 10.1233ZM9.05206 10.1233C9.05206 10.5709 9.39766 10.9338 9.82399 10.9338C10.2503 10.9338 10.5959 10.5709 10.5959 10.1233C10.5959 9.67563 10.2503 9.31275 9.82399 9.31275C9.39766 9.31275 9.05206 9.67563 9.05206 10.1233Z"
                        fill="#085950"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.3687 5.83762L18.4021 9.22156C18.6629 9.44076 18.8147 9.77251 18.8147 10.1233C18.8147 10.474 18.6629 10.8058 18.4021 11.025L14.3687 14.4089C11.7104 16.6369 7.93757 16.6369 5.27925 14.4089L1.24592 11.025C0.985118 10.8058 0.833313 10.474 0.833313 10.1233C0.833313 9.77251 0.985118 9.44076 1.24592 9.22156L5.27925 5.83762C7.93757 3.60963 11.7104 3.60963 14.3687 5.83762ZM6.17662 13.2742C8.31139 15.0582 11.3366 15.0582 13.4714 13.2742L17.1573 10.1233L13.4714 7.02301C11.3366 5.239 8.31139 5.239 6.17662 7.02301L2.49065 10.1233L6.17662 13.2742Z"
                        fill="#085950"
                      />
                    </svg>
                  </Button>
                  <ButtonSummary
                    file={file}
                    tocError={fileErrors[i] || false}
                    openSummary={fileErrors[i] ? handleRetry : openSummary}
                    messages={messages}
                    index={i}
                  />
                </div>
              ) : (
                <LinearProgressWithLabel className="mt-2" value={Math.round((file.uploaded / file.parts) * 100)} />
              )}
            </li>
          ))}

          {fileError ? (
            <div className="warning-alert">
              <Alert
                severity="error"
                onClose={() => {
                  setFileError(false);
                }}
              >
                {messages.TheFileLarge}
              </Alert>
            </div>
          ) : (
            ''
          )}
        </ul>
      </div>
    );
  };
  const changeModel = (model) => {
    if (model === 'chatFiles') {
      handelFileDrawer();
    } else {
      setDrawerOpen(false);
    }
    setModel(model);
  };
  const [hasFilesBeenFetched, setHasFilesBeenFetched] = useState(false);
  const handelFileDrawer = () => {
    toggleDrawer();
    if (!hasFilesBeenFetched) {
      getFiles();
      setHasFilesBeenFetched(true); // Update the state to prevent future fetches
    }
  };

  function handleAddAudio(base64data) {
    axios
      .post(
        CHAT_URL + '/stt',
        {
          user_id: userId,
          audio: base64data,
          lang: selectedVoiceLang,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then((response) => {
        setQuestion(response.data.text);
        console.log(response, "audio")
      })
      .catch((error) => {
        console.log(error, "erro");
      });
  }

  return (
    <div className="chatInput">
      <FormControl className="chatInput__form">
        <Textarea
          onKeyPress={onEnterPress}
          className="chatMessage"
          placeholder="How can I help you?"
          minRows={3}
          onKeyDown={(e) => e.key === 'Enter' && send}
          disabled={chatInputDisabled}
          className={'textarea'}
          endDecorator={
            <Box className="flex items-center justify-between w-full chatInput__control">
              <div className={'files-upload'}>
                <SelectModel onSelectModel={changeModel} variant="soft" className="chatCategory" options={options} />

                {model == chatMessages.chatFiles.id && (
                  <div
                    id="uploadFileBtn"
                    title="Upload File"
                    onClick={handelFileDrawer}
                    className={drawerOpen ? 'drawer-open' : ''}
                    style={{
                      marginInlineEnd: '1rem',
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path
                        d="M16.4002 8.9502L10.1575 14.9815C8.19991 16.8728 5.02594 16.8728 3.06832 14.9815C1.10351 13.0832 1.11177 10.003 3.08675 8.11466L8.84774 2.57055C10.1872 1.27646 12.3588 1.27645 13.6983 2.57052C15.0426 3.86934 15.0369 5.97682 13.6856 7.26891L7.85982 12.8642C7.13676 13.5628 5.96437 13.5628 5.24127 12.8642C4.51816 12.1656 4.51816 11.0329 5.24128 10.3343L10.0656 5.67336"
                        stroke="#ACB4C0"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                )}

                <div
                  style={{
                    display: 'flex',
                    gap: '1rem',
                  }}
                >
                  <AudioRecorder handleAddAudio={handleAddAudio} />
                  <LanguageButton selectedVoiceLang={selectedVoiceLang} setSelectedVoiceLang={setSelectedVoiceLang} />
                </div>
              </div>

              <Tooltip title="Send Message" placement="left">
                <Button
                  className="btn__primary"
                  sx={{
                    marginInlineStart: 'auto',
                  }}
                  onClick={send}
                  disabled={inputDisabled}
                >
                  <img src={sendIcon} width={10} className={'send'} />
                  <span>Send message</span>
                </Button>
              </Tooltip>
            </Box>
          }
          sx={{
            minWidth: 300,
            fontWeight,
            fontStyle: italic ? 'italic' : 'initial',
          }}
          value={chatting ? '' : question}
          onChange={(e) => {
            setQuestion(e.target.value);
            // e.target.value.trim().length == 0 ? setRecord(true) : setRecord(false);
          }}
        />
        {drawers()}
        <Modal
          open={openPreviewModal}
          onClose={handleClosePreviewModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            position: 'absolute',
            zIndex: '99999 !important',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '30px',
              left: '30px',
              // transform: 'translate(0%, -10%)',
              width: 'calc(100% - 60px)',
              // bgcolor: 'background.paper',
              bgcolor: '#333',
              border: '2px solid rgba(0,0,0, 0.5)',
              boxShadow: 24,
              p: 2,
              borderRadius: '8px',
            }}
          >
            <Typography
              className="modal-modal-title d-flex justify-content-between align-items-baseline"
              variant="h6"
              component="h2"
            >
              {modalFileName}
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClosePreviewModal} ml={1}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Typography>
            <div className="preview-content" sx={{ mt: 2 }}>
              <object className="text-tag" width="100%" height="100%" data={fileUrl}>
                <p>
                  {messages.UnableToDisplayTheFile}
                  <a href={fileUrl}>{messages.withDownload}</a>
                  {messages.instead}
                </p>
              </object>
            </div>
          </Box>
        </Modal>
        <Snackbar
          open={errorAlert}
          autoHideDuration={1500}
          onClose={closeErrorAlert}
          message={errorMsg}
          action={action}
          className="alert-error"
          severity="error"
          variant="filled"
        />
      </FormControl>
      <ToastRenderer />
    </div>
  );
}
