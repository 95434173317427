import React, { useState, useEffect } from 'react';
import { List, Typography, Card, Box, Dialog, Button } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import SearchLoadingImg from '../../images/loading.gif';

function DomainsSelect({ onChoose, domains, open, onClose }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleSearch = (domain) => {
    onChoose(domain);
    onClose(); // Close the modal when an item is selected
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
          <img src={SearchLoadingImg} className="loading-img" alt="Loading" />
        </Box>
      ) : (
        <List>
          <Box sx={{ minHeight: 352, minWidth: 250 }}>
            <SimpleTreeView>
              {domains.map((domain, index) => (
                <TreeItem
                  key={`tree-item-${index}`}
                  id={`tree-item-${index}`}
                  itemId={`tree-item-${index}`}
                  label={domain.fields}
                  onClick={() => {
                    if (domain.sub_fields.length === 0) {
                      handleSearch(domain.fields);
                    }
                  }}
                >
                  {domain.sub_fields.length > 0 &&
                    domain.sub_fields.map((sub_field, pos) => (
                      <TreeItem
                        key={`sub-tree-item-${index}-${pos}`}
                        id={`sub-tree-item-${index}-${pos}`}
                        itemId={`sub-tree-item-${index}-${pos}`}
                        label={sub_field}
                        onClick={() => handleSearch(sub_field)}
                      />
                    ))}
                </TreeItem>
              ))}
            </SimpleTreeView>
          </Box>
        </List>
      )}
    </Dialog>
  );
}

export default DomainsSelect;
