import React from 'react';
import { useLocation } from 'react-router-dom';
import { ActiveUsers, Container, IconUsers } from './logo.styles';

export interface NewsProps {
  className?: string;
}

export const Users = ({ className }: NewsProps) => {
  const location = useLocation();

  return (
    <Container className={className}>{location.pathname === '/users' ? <ActiveUsers /> : <IconUsers />}</Container>
  );
};
