import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import debounce from 'lodash.debounce';
import Search from '../../images/svg/search';
import Mac from '../../images/svg/mac';

export default function SearchAppBar({ onSearch, placeholder }) {
  const [searchValue, setSearchValue] = useState('');
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch(searchValue);
    }
  };

  const WAIT_INTERVAL = 600;
  const handleSearch = React.useCallback(debounce(onSearch, WAIT_INTERVAL), [onSearch]);

  function handleChange(event) {
    setSearchValue(event.target.value);
    handleSearch(event.target.value);
  }

  return (
    <Box>
      <div className="searchInput">
        <div className="searchInput__icon">
          <Search />
        </div>
        <input
          placeholder={placeholder}
          type="text"
          onChange={handleChange}
          value={searchValue}
          onKeyDown={handleKeyDown}
        />
        {/*<div className="shortCut">*/}
        {/*  <Mac />*/}
        {/*  <span>K</span>*/}
        {/*</div>*/}
      </div>
    </Box>
  );
}

SearchAppBar.defaultProps = {
  placeholder: 'Search for something...',
};
