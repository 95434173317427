// import { onAuthStateChanged, signInWithPopup, signOut } from 'firebase/auth';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import { ROUTES } from '../../../routes/app.constants';
// import {getVerifyUser} from '../../services/api/endpoints/contentful';
// import { auth, googleProvider } from '../../services/firebase';
import { reportError } from '../../utils/reportError';
import axiosBackendApi from '../../services/api/axiosBackendInstance';
interface State {
  user: AdminPanelUser | null;
  isLoading: boolean;
  errorMessage: string;
  signIn: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

export const AdminPanelContext = createContext<State | undefined>(undefined);

interface AdminPanelContextProviderProps {
  children: ReactNode;
}

interface AdminPanelUser {
  id: string;
  name: string;
  email: string;
  token: string;
  role: [];
  permissions: [];
}

export const AdminPanelContextProvider = ({ children }: AdminPanelContextProviderProps) => {
  const history = useHistory();
  const [user, setUser] = useState<AdminPanelUser | null>(() => {
    const localData = localStorage.getItem('user');
    return localData ? JSON.parse(localData) : [];
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const signIn = async (email: string, password: string) => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await axiosBackendApi.post('admin/user/auth/login', {
        email: email,
        password: password,
      });
      // await getVerifyUser(user.email || '');
      if (typeof response.data.data.user_details !== 'undefined') {
        try {
          // await getVerifyUser(user.email || '');
          const user = {
            id: response.data.data.user_details.id,
            name: response.data.data.user_details.full_name,
            email: response.data.data.user_details.email,
            token: response.data.data.access_token,
            role: response.data.data.user_details.role,
            permissions: response.data.data.user_details.permissions,
          };
          setUser(user);
          console.log(user.token);
          localStorage.setItem('user', JSON.stringify(user));

          axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
          axios.defaults.headers['Authorization'] = 'Bearer ' + user.token;
          

          axiosBackendApi.interceptors.request.use((config) => {
            if(config.headers){
              config.headers['Authorization'] =
                'Bearer ' + user.token;
              config.params = {
                ...config.params,
              };
            }
            
            if(config.headers.common){
              config.headers.common['Authorization'] =
                'Bearer ' + user.token;
              config.params = {
                ...config.params,
              };
            }


            return config;
          });
        } catch (err) {
          setUser(null);
          await logout();
        }
        return setIsLoading(false);
      }
      // setUser(null);
      // setIsLoading(false);

      // history.push(ROUTES.home);
      setErrorMessage('');
    } catch (err) {
      reportError(err);
      setErrorMessage('Invalid email or password');
      console.log(err.message);
    }
    setIsLoading(false);
  };

  const logout = async () => {
    setIsLoading(true);
    await axiosBackendApi.post('admin/user/auth/logout');
    axios.defaults.headers.common['Authorization'] = '';
    setUser(null);
    localStorage.removeItem('user');
    setIsLoading(false);
    history.push(ROUTES.login);
    window.location.reload()
  };

  const value = { user, isLoading, errorMessage, signIn, logout };

  return <AdminPanelContext.Provider value={value}>{children}</AdminPanelContext.Provider>;
};

export const useAdminPanelContext = () => {
  const context = useContext(AdminPanelContext);

  if (!context) {
    throw new Error('AdminPanelContext used outside of Provider!');
  }

  return context;
};
