import React from 'react';
import { useLocation } from 'react-router-dom';
import { ActiveNews, Container, IconNews } from './logo.styles';

export interface NewsProps {
  className?: string;
}

export const News = ({ className }: NewsProps) => {
  const location = useLocation();

  return (
    <Container className={className}>{location.pathname === '/news-letter' ? <ActiveNews /> : <IconNews />}</Container>
  );
};
