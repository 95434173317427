import axios, { AxiosError, AxiosResponse } from 'axios';
const { REACT_APP_WEB_API } = process.env;
import toast, { Toaster } from 'react-hot-toast';

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(localStorage.getItem('user') || '{}')?.token;

const axiosBackendApi = axios.create({
  baseURL: REACT_APP_WEB_API,
});

axiosBackendApi.interceptors.request.use((config) => {
  // Adding default parameters here if needed
  config.params = {
    ...config.params,
  };
  return config;
});

axiosBackendApi.interceptors.response.use(response => {
    console.log("Interceptor Response:", response);
    return response;
  },
  error => {
    if (error.response) {
      // Specific error handling for 401
      if (error.response.status === 401) {
        console.error('Error 401: Redirecting to login');
        toast.error('Session expired, please login again.', {
          duration: 4000,
          position: "bottom-center",
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        });
        // window.location.href = '/login';
        return Promise.reject(error);
      }
    } else if (error.request) {
      console.error('Backend Network Error:', error.request);
    } else {
      console.error('Error without response:', error.message);
    }

    toast.error('An error occurred', {
      duration: 4000,
      position: "bottom-center",
      style: {
        border: '1px solid #713200',
        padding: '16px',
        color: '#713200',
      },
    });
    return Promise.reject(error);
  });

export default axiosBackendApi;
