import React from 'react';
import { useLocation } from 'react-router-dom';
import { ActiveChat, Container, IconChat } from './logo.styles';

export interface ChatProps {
  className?: string;
}

export const Chat = ({ className }: ChatProps) => {
  const location = useLocation();
  return (
    <Container className={className}>
      {location.pathname === '/chat-llm' ||
      location.pathname === '/chat-rag-online' ||
      location.pathname === '/chat-rag-offline' ||
      location.pathname === '/chat-files' ||
      location.pathname === '/chat-brief7B' ||
      location.pathname === '/chat-brief13B' ? (
        <ActiveChat />
      ) : (
        <IconChat />
      )}
    </Container>
  );
};
