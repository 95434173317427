// @ts-nocheck
import copy from "../../images/copy.svg";
const SEARCH_URL = process.env.REACT_APP_WEB_API;
import { Card, CardContent, Typography, IconButton, CardMedia, Grid, Modal, Box } from '@mui/material';
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import axiosBackendApi from "../../shared/services/api/axiosBackendInstance";
import Markdown from "markdown-to-jsx";
import SearchLoadingImg from '../../images/loading.gif';
import Copy from '../../images/svg/copy';
import TranslateIcon from '../../images/svg/translate';
import Close from '../../images/svg/close';
import Reload from '../../images/svg/reload';


export const NewsletterAnswer = ({ message }) => {
  const copyMessage = async (message: string) => {
    if (translate) {
      message = messageTranslated;
    }
    try {
      var textArea = window.document.createElement('textarea');
      textArea.style.position = 'fixed';
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = '0';
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';
      textArea.value = message.replace(/(<([^>]+)>)/gi, '');
      window.document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      window.document.execCommand('copy');
      window.document.body.removeChild(textArea);
    } catch (e) {
      console.log(e)
    }
  };
  const [loader, setLoader] = useState(false)
  const [translate, setTranslate] = useState(false);
  const [messageTranslated, setMessageTranslated] = useState('');
  const CHAT_URL = process.env.REACT_APP_CHAT_API;
  const chatMessagesend = (content) => {
    return content.toString();
  }
  useEffect(() => {
    if (translate) {
      let data = {
        query: message.message
      }
      setLoader(true)

      axiosBackendApi.post(CHAT_URL + '/translate', data).then((response) => {
        setLoader(false)
        setMessageTranslated(response.data.translate)
      });
    }

  }, [translate]);

  useEffect(() => {
    console.log(message)

  }, []);
  console.log({ loader, translate })

  const generate = (message) => {
    // fetchData('/chat_regenerate', message.message);
  }

  const handelClick = () => {
    generate()
  }
  return (
    <div>
      <Typography sx={{ marginTop: 2 }}>
        {loader ? (
          <div className="loading" style={{ width: '100px' }}>
            <div className="loading__typing"></div>
          </div>
        ) : translate ? (
          <p dir={'rtl'}>
            <Markdown
              children={chatMessagesend(messageTranslated)}
              options={{
                overrides: {},
              }}
            />
          </p>
        ) : (
          <Markdown
            children={chatMessagesend(message.message)}
            options={{
              overrides: {},
            }}
          />
        )}
      </Typography>
      {message.type === 'answer' && (
        loader ? (
          // <div className="loading" style={{ width: '100px' }}>
          //   <div className="loading__typing"></div>
          // </div>
          <div></div>
        ) : (
          <Typography sx={{ display: message.type != 'question' ? 'flex' : 'none', alignItems: 'center', marginTop: 2, }}>
            <>
              <IconButton className="btn__icon" onClick={() => copyMessage(message.message)}>
                <Copy />
              </IconButton>
              {
                !translate ? (
                  <Tooltip title="Translation" placement="top">
                    <IconButton
                      onClick={() =>
                        setTranslate(() => {
                          return !translate;
                        })
                      }
                      className="color-main"
                    >
                      <TranslateIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Close Translation" placement="top" onClick={() => setTranslate(false)}>
                    <IconButton className="btn__icon">
                      <Close />
                    </IconButton>
                  </Tooltip>
                )
              }
              {!translate && (
                <IconButton className="btn__icon" onClick={handelClick}>
                  <Reload />
                </IconButton>
              )}
            </>
          </Typography>
        )
      )}
    </div>
  );
};
