// @ts-nocheck
import { ThemeProvider } from 'styled-components';
import { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TagSize } from '../tag/tag.types';
import { ROUTES } from '../../../routes/app.constants';
import {
  Container,
  TitleWithTagWrapper,
  Title,
  VersionTag,
  Logout,
  Chat,
  Search,
  Radar,
  Visualisation,
  News,
  Users,
  Profile,
  LogoLink,
  CompanyText,
  TopContainer,
  OverallContainer,
  ContentContainer,
} from './titleTag.styles';
import { TitleTagSize, TitleTagTheme } from './titleTag.types';
import titleMessages from './titleTag.messages';
import Button from '@mui/material/Button';
import * as React from 'react';
import wakebLight from '../../../images/authority-icon-colored.png';
import wakebDark from '../../../images/authority-icon.png';
import AddIcon from '@mui/icons-material/Add';
import Link from '@mui/material/Link';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { IconButton } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';

export interface TitleTagProps {
  size?: TitleTagSize;
  withLogo?: boolean;
  className?: string;
  withCompanyText?: boolean;
  locale: string;
  messages: object;
}

export const TitleTag = ({
  size = TitleTagSize.LARGE,
  withLogo = false,
  withCompanyText = false,
  className,
  locale,
  messages,
}: TitleTagProps) => {
  const theme: TitleTagTheme = { size };
  const [mobileSidebar, setMobileSidebar] = useState(false);

  const user = localStorage.getItem('user');
  const roleName = JSON.parse(user).role?.name;

  useEffect(() => {}, []);

  const LogoContent = ({ children }: { children: ReactNode }) => (
    <ThemeProvider theme={theme}>
      <Container className={className}>
        <IconButton
          className="navbar-toggler"
          onClick={() => {
            setMobileSidebar(!mobileSidebar);
          }}
        >
          <SortIcon />
        </IconButton>
        <div className={mobileSidebar ? 'side-menu mobile-open' : 'side-menu'}>
          <div>
            <Link href={ROUTES.home} className="light-theme">
              <img src={wakebLight} title={messages.GeneralAuthority} />
            </Link>
            <Link href={ROUTES.home} className="dark-theme">
              <img src={wakebDark} title={messages.GeneralAuthority} />
            </Link>

            <div className="logos">
              {
                <LogoLink to={ROUTES.search} withBorder={false} title={messages.search}>
                  <Search />
                </LogoLink>
              }
              {
                <LogoLink to={ROUTES.Chat} withBorder={false} title={messages.chat}>
                  <Chat />
                </LogoLink>
              }
              {/*{*/}
              {/*  <LogoLink to={ROUTES.radar} withBorder={false} title={messages.radar}>*/}
              {/*    <Radar />*/}
              {/*  </LogoLink>*/}
              {/*}*/}
              {
                <LogoLink to={ROUTES.visualisation} withBorder={false} title={messages.visualizations}>
                  <Visualisation />
                </LogoLink>
              }
              {
                <LogoLink to={ROUTES.newsLetter} withBorder={false} title={messages.newsLetter}>
                  <News />
                </LogoLink>
              }
              {roleName == 'Super Admin' ? (
                <LogoLink to={ROUTES.users} withBorder={false} title={messages.Users}>
                  <Users />
                </LogoLink>
              ) : (
                ''
              )}
              {/*{*/}
              {/*  <LogoLink to="#" withBorder={false}>*/}
              {/*    <Logout />*/}
              {/*  </LogoLink>*/}
              {/*}*/}
            </div>
          </div>
          <div>
            {/*<LogoLink to={ROUTES.profil} withBorder={false} title="Profile">*/}
            {/*  /!*<PersonOutlineOutlinedIcon />*!/*/}
            {/*</LogoLink>*/}

            <LogoLink to={ROUTES.profile} withBorder={false} title={messages.Profile} className="profile-icon">
              <Profile />
            </LogoLink>

            <LogoLink to="#" withBorder={false} className="btn-logout" title={messages.Logout}>
              <Logout />
            </LogoLink>
          </div>

          {/*<Button className="btn-logout">*/}
          {/*  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">*/}
          {/*    <path*/}
          {/*      d="M12 8L16 12M16 12L12 16M16 12H3M3.33782 7C5.06687 4.01099 8.29859 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C8.29859 22 5.06687 19.989 3.33782 17"*/}
          {/*      stroke="white"*/}
          {/*      strokeWidth="2"*/}
          {/*      strokeLinecap="round"*/}
          {/*      strokeLinejoin="round"*/}
          {/*    />*/}
          {/*  </svg>*/}
          {/*</Button>*/}
        </div>
      </Container>
    </ThemeProvider>
  );

  if (withCompanyText) {
    return (
      <LogoContent>
        <ContentContainer>
          <TopContainer>
            <TitleWithTagWrapper>
              <Title>
                <FormattedMessage {...titleMessages.title} />
              </Title>
            </TitleWithTagWrapper>
          </TopContainer>
          {withCompanyText && <CompanyText>{/*<FormattedMessage {...titleMessages.byCompany} />*/}</CompanyText>}
        </ContentContainer>
        {/*<VersionTag size={size === TitleTagSize.LARGE ? TagSize.LARGE : TagSize.SMALL} />*/}
      </LogoContent>
    );
  }

  return (
    <LogoContent>
      <TitleWithTagWrapper>
        <Title>
          <FormattedMessage {...titleMessages.title} />
        </Title>
        <VersionTag size={size === TitleTagSize.LARGE ? TagSize.LARGE : TagSize.SMALL} />
      </TitleWithTagWrapper>
    </LogoContent>
  );
};
