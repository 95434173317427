export default function Search() {
  return (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.7 12.2003L9.01039 9.51064M1.30005 6.28305C1.30005 3.80729 3.30705 1.80029 5.78281 1.80029C8.25858 1.80029 10.2656 3.80729 10.2656 6.28305C10.2656 8.75883 8.25858 10.7658 5.78281 10.7658C3.30705 10.7658 1.30005 8.75883 1.30005 6.28305Z"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
