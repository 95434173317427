import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TranslateIcon from '@mui/icons-material/Translate';
import Tooltip from '@mui/material/Tooltip';

function LanguageDropdown({
  selectedVoiceLang,
  setSelectedVoiceLang,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const languages = ['Auto', 'En', 'ع'];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (language) => {
    // console.log(`Selected language: ${language}`);
    setSelectedVoiceLang(language);
    handleClose();
  };

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
    }}>
      <Tooltip title="Language" placement="bottom">
        <IconButton
          sx={{ color: 'rgba(189,189,189,0.82)', width: '2.5rem', height: '2.5rem' }}
          aria-label="language"
          aria-controls="language-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          {selectedVoiceLang === 'Auto' ? (
            <TranslateIcon />
          ) : (
            <span
              style={{
                fontSize: '1.2rem',
              }}
            >
              {selectedVoiceLang}
            </span>
          )}
        </IconButton>
      </Tooltip>

      <Menu id="language-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {languages.map((language) => (
          <MenuItem key={language} onClick={() => handleMenuItemClick(language)}>
            {language}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default LanguageDropdown;
