import * as React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import Link from '@mui/material/Link';
import { ROUTES } from '../../app.constants';
import wakebLight from '../../../images/AI-Platform-Light-0.png';
import { LoginWrapper } from './login.styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import '@fontsource/inter';
import '@fontsource/karla';
import '@fontsource/cabin';
import axios from 'axios';
import { useHistory } from 'react-router';
import { useAdminPanelContext } from '../../../shared/components/adminPanel/adminPanelContext';
import { useLocation } from 'react-router-dom';
import { DEFAULT_LOCALE, translationMessages } from '../../../i18n';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

const SEARCH_URL = process.env.REACT_APP_WEB_API;

export const ForgetPassword = ({ locale, messages }) => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/account-recovery') {
      let appDiv = document.getElementById('app');
      appDiv.style.padding = '0';
      appDiv.style.marginLeft = '0';
      appDiv.style.maxWidth = '100%';
    }
  }, [location.pathname]);

  const [email, setEmail] = React.useState('');
  const [errorForget, setErrorForget] = React.useState('');
  const [loadingForget, setLoadingForget] = React.useState(false);
  const [showForgetPassword, setShowForgetPassword] = React.useState(true);
  const handleForgetPassword = () => {
    setErrorForget('');
    setLoadingForget(true);
    axios
      .post(SEARCH_URL + '/admin/user/auth/forget-password', {
        email: email,
        method: 1,
      })
      .then((response) => {
        if (response.status === 200) {
          setShowOptCheck(true);
          setShowForgetPassword(false);
          setErrorForget('');
        }
      })
      .catch((error) => {
        setErrorForget(error.response?.data?.message);
      })
      .finally(() => {
        setLoadingForget(false);
      });
  };

  const [otp, setOtp] = React.useState('');
  const [showOptCheck, setShowOptCheck] = React.useState(false);
  const [errorCheck, setErrorCheck] = React.useState('');
  const [loadingCheck, setLoadingCheck] = React.useState(false);
  const checkResetPassword = () => {
    setErrorCheck('');
    setLoadingCheck(true);
    axios
      .post(SEARCH_URL + '/admin/user/auth/check-reset-password-otp', {
        email: email,
        method: 1,
        otp: otp,
      })
      .then((response) => {
        if (response.status === 200) {
          setShowResetPassword(true);
          setShowOptCheck(false);
          setErrorCheck('');
        }
      })
      .catch((error) => {
        setErrorCheck(error.response?.data?.message);
      })
      .finally(() => {
        setLoadingCheck(false);
      });
  };

  const [password, setPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
  const [showResetPassword, setShowResetPassword] = React.useState(false);
  const [errorReset, setErrorReset] = React.useState('');
  const [loadingReset, setLoadingReset] = React.useState(false);

  const handleResetPassword = () => {
    setErrorReset('');
    setLoadingReset(true);
    axios
      .post(SEARCH_URL + '/admin/user/auth/reset-password', {
        email: email,
        method: 1,
        otp: otp,
        password: password,
        password_confirmation: passwordConfirmation,
      })
      .then((response) => {
        history.push(ROUTES.login);
      })
      .catch((error) => {
        setErrorReset(error.response?.data?.message);
      })
      .finally(() => {
        setLoadingReset(false);
      });
  };

  return (
    <LoginWrapper>
      <Helmet
        title={intl.formatMessage({
          id: 'accountRecovery',
          defaultMessage: locale === 'en' ? 'Account recovery' : 'استرجاع الحساب',
        })}
      />
      <div className="login-page">
        <video id="background-video" autoPlay loop muted poster="../../../images/login-bg-light.jpg">
          <source src="/login-bg-light.mp4" type="video/mp4" />
        </video>
        <div className="logo-side">
          <div>
            <img src={wakebLight} alt="Wakeb" className="login-logo light" loading="lazy"  />
          </div>
          <div>
            <Typography variant="h1" className="mb-0">
              {messages.AIP}
            </Typography>
            <Typography variant="h1">{messages.powerOfAI}</Typography>
            <Typography variant="h5">
              {messages.ChatWithTheSmartestAI}
              <br />
              {messages.ExperienceThePowerOfAIWithUs}
            </Typography>
          </div>
          <Typography variant="h6">{messages.WakebAllRightReserved}</Typography>
        </div>
        <div className="login-content">
          {showForgetPassword && (
            <Box sx={{ width: 500, maxWidth: '100%' }}>
              <Typography variant="h5">{messages.ForgotYourAccountPassword}</Typography>
              <Typography variant="h6">{messages.EnterYourEmailAddressAndWeWillSendYouARecoveryLink}</Typography>
              <form onSubmit={(e) => {
                e.preventDefault();
                handleForgetPassword();
              }}>
                <FormControl sx={{ mb: 1, width: '100%' }}>
                  <InputLabel htmlFor="email">{messages.EmailAddress}</InputLabel>
                  <OutlinedInput
                    id="email"
                    type="email"
                    value={email}
                    label={messages.EmailAddress}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                {errorForget.length ? <div className="text-danger">{errorForget}</div> : ''}

                <div className="mt-3">
                  <Button
                    variant="contained"
                    color="success"
                    className={loadingForget ? 'cursor-not-allowed' : ''}
                    onClick={handleForgetPassword}
                  >
                    {messages.SendRecoveryEmail}
                  </Button>
                </div>
              </form>
            </Box>
          )}
          {showOptCheck && (
            <Box sx={{ width: 500, maxWidth: '100%' }}>
              <Typography variant="h5">
                {messages.AccountRecoveryCodeSentTo} {email}
              </Typography>
              <Typography variant="h6">{messages.CheckYourEmailForACodeToResetYourPassword}</Typography>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  checkResetPassword();
                }}
              >
                <FormControl sx={{ mb: 1, width: '100%' }}>
                  <InputLabel htmlFor="otp">{messages.EnterTheCode}</InputLabel>
                  <OutlinedInput
                    id="otp"
                    type="text"
                    value={otp}
                    label={messages.EnterTheCode}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </FormControl>
                {errorCheck.length ? <div className="text-danger">{errorCheck}</div> : ''}

                <div className="mt-3">
                  <Button
                    variant="contained"
                    color="success"
                    className={loadingCheck ? 'cursor-not-allowed' : ''}
                    onClick={checkResetPassword}
                  >
                    {messages.CheckCode}
                  </Button>
                </div>
              </form>
            </Box>
          )}
          {showResetPassword && (
            <Box sx={{ width: 500, maxWidth: '100%' }}>
              <Typography variant="h5">{messages.ResetYourPassword}</Typography>
              <Typography variant="h6">{messages.MakeSureItAtLeast}</Typography>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleResetPassword();
                }}
              >
                <FormControl sx={{ mb: 4, width: '100%' }}>
                  <InputLabel htmlFor="password">{messages.Password}</InputLabel>
                  <OutlinedInput
                    id="password"
                    type="password"
                    value={password}
                    label={messages.Password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormControl>
                <FormControl sx={{ mb: 1, width: '100%' }}>
                  <InputLabel htmlFor="passwordConfirmation">{messages.ConfirmYourPassword}</InputLabel>
                  <OutlinedInput
                    id="passwordConfirmation"
                    type="password"
                    value={passwordConfirmation}
                    label={messages.ConfirmYourPassword}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                  />
                </FormControl>
                {errorReset.length ? <div className="text-danger">{errorReset}</div> : ''}

                <div className="mt-3">
                  <Button
                    variant="contained"
                    color="success"
                    className={loadingReset ? 'cursor-not-allowed' : ''}
                    onClick={handleResetPassword}
                  >
                    {messages.ChangePassword}
                  </Button>
                </div>
              </form>
            </Box>
          )}
        </div>
      </div>
    </LoginWrapper>
  );
};
