import React from 'react';
import { useLocation } from 'react-router-dom';
import { ActiveProfile, Container, IconProfile } from './logo.styles';

export interface NewsProps {
  className?: string;
}

export const Profile = ({ className }: NewsProps) => {
  const location = useLocation();

  return (
    <Container className={className}>
      {location.pathname === '/profile' ? <ActiveProfile /> : <IconProfile />}
    </Container>
  );
};
