import React from 'react';
import {useLocation} from 'react-router-dom';
import {ActiveRadar, Container, IconRadar} from './logo.styles';

export interface RadarProps {
  className?: string;
}

export const Radar = ({className}: RadarProps) => {
  const location = useLocation();

  return <Container className={className}>{location.pathname === '/radar' ? <ActiveRadar/> : <IconRadar/>}</Container>;
};
