/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'titleTag.title',
    defaultMessage: 'AIP',
  },
  byCompany: {
    id: 'titleTag.byCompany',
    defaultMessage: '',
  },
});
