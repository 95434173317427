// @ts-nocheck
import { Redirect, Route, Switch } from 'react-router-dom';
import { Component } from 'react';
import { IntlProvider } from 'react-intl';
import '../assets/css/style.css';
import { AdminPanelContextProvider } from '../shared/components/adminPanel/adminPanelContext';
import { AdminRoute } from '../shared/components/routes/adminRoute';
import { DEFAULT_LOCALE, translationMessages } from '../i18n';
import { asyncComponent } from '../shared/utils/asyncComponent';
import { AppComponent as App } from './app.component';
import NewsLetterDetail from './newNewsLetter/NewsLetterDetail';
import NewsLetterArticle from './newNewsLetter/newslettersArticle';
import { ROUTES } from './app.constants';
import { Login as AdminLogin } from './admin/login';
import { ForgetPassword as AdminForgetPassword } from './admin/login';
import { PasswordReset as AdminPasswordReset } from './admin/login';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
//<-- IMPORT ROUTE -->1

// @ts-ignore
const Home = asyncComponent(() => import('./home'), 'Home');
// @ts-ignore
const Search = asyncComponent(() => import('./search'), 'Search');
const Layout = asyncComponent(() => import('./layout'), 'Layout');
const Landing = asyncComponent(() => import('./landing'), 'Landing');
// @ts-ignore
const NotFound = asyncComponent(() => import('./notFound'), 'NotFound');
// @ts-ignore
const ChatLLM = asyncComponent(() => import('./chat/llm'), 'ChatLLM');
const ChatFiles = asyncComponent(() => import('./chat/files'), 'ChatFiles');
const ChatRAGOnline = asyncComponent(() => import('./chat/rag-online'), 'ChatRAGOnline');
const ChatRAGOffline = asyncComponent(() => import('./chat/rag-offline'), 'ChatRAGOffline');
const ChatBrief7B = asyncComponent(() => import('./chat/brief7b'), 'ChatBrief7B');
const ChatBrief13B = asyncComponent(() => import('./chat/brief13b'), 'ChatBrief13B');
// @ts-ignore
// const Radar = asyncComponent(() => import('./radar'), '_Radar');
const Visualisation = asyncComponent(() => import('./visualisation'), 'Visualisation');
// @ts-ignore
const NewsLetter = asyncComponent(() => import('./newsLetter'), 'NewsLetter');
const Scraping = asyncComponent(() => import('./scraping'), 'Scraping');
const NewNewsLetter = asyncComponent(() => import('./newNewsLetter'), 'NewNewsLetter');
const ForgetPassword = asyncComponent(() => import('./admin/login/forgetPassword.component'), 'ForgetPassword');
// @ts-ignore
const UnsubscribeNewsletter = asyncComponent(() => import('./newsLetter'), 'UnsubscribeNewsletter');
const CreateNewsLetter = asyncComponent(() => import('./newsLetter'), 'CreateNewsLetter');
// @ts-ignore
const Users = asyncComponent(() => import('./users'), 'Users');
// @ts-ignore
const Profile = asyncComponent(() => import('./profile'), 'Profile');
// @ts-ignore
const Login = asyncComponent(() => import('./admin/login'), 'Login');
// @ts-ignore
const AdminPanel = asyncComponent(() => import('./admin/adminPanel'), 'AdminPanel');
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { IconButton } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import * as React from 'react';
import {ChatNew} from "./chat/new-chat";
import { useLanguage } from '../modules/lang/lang.context';


const style = document.createElement('style');

export default () => {
  const location = useLocation();

  let themeText = localStorage.getItem('localTheme');
  if (!themeText) {
    localStorage.setItem('localTheme', 'light');
    themeText = 'light';
  }
  const [theme, setTheme] = useState(themeText);
  const changeTheme = function () {
    if (theme === 'dark') {
      localStorage.setItem('localTheme', 'light');
      applyTheme('light');
    } else {
      localStorage.setItem('localTheme', 'dark');
      applyTheme('dark');
    }
  };
  const applyTheme = function (theme) {
    if (theme === 'light') {
      setTheme('light');
      var head = document.getElementsByTagName('head')[0];
      var link = document.createElement('link');
      link.id = 'themeLink';
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = '/css/style-light.css';
      link.media = 'all';
      head.appendChild(link);
    } else {
      setTheme('dark');
      const element = document.getElementById('themeLink');
      if (element) element.remove();
    }
  };

  //language
  let langText = localStorage.getItem('localLang');
  if (!langText) {
    localStorage.setItem('localLang', 'en');
    langText = 'en';
  }
  const [language, setLanguage] = useState(langText);

  // const [locale, setLocale] = useState('en');
  const { locale, setLocale }  = useLanguage();
  const changeLang = function () {
    if (language === 'ar') {
      localStorage.setItem('localLang', 'en');
      applyLang('en');
    } else {
      localStorage.setItem('localLang', 'ar');
      applyLang('ar');
    }
  };

  const applyLang = function (lang) {
    setLocale(lang);
    if (lang === 'ar') {
      setLanguage('ar');
      var head = document.getElementsByTagName('head')[0];
      var link = document.createElement('link');
      link.id = 'langLink';
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = '/css/ar.css';
      link.media = 'all';
      head.appendChild(link);
    } else {
      setLanguage('En');
      const element = document.getElementById('langLink');
      if (element) element.remove();
    }
  };

  const user = localStorage.getItem('user');
  const roleName = JSON.parse(user)?.role?.name;

  useEffect(() => {
    setLocale('en');
    applyTheme(themeText);
    applyLang(langText);
  }, []);

  console.log({ translationMessages, locale })
  return (
    <Switch>
      <App>
        <div>
          {location.pathname == '*' ? (
            ''
          ) : (
            <div>
              <div
                id="theme-btn"
                className={
                  location.pathname === '/login' ||
                  location.pathname === '/account-recovery' ||
                  location.pathname === '/password-reset'
                    ? 'theme-btn cursor-pointer login'
                    : 'theme-btn cursor-pointer'
                }
              >
                <IconButton
                  title={theme == 'dark' ? translationMessages[locale]?.light : translationMessages[locale]?.dark}
                  className={theme}
                  onClick={() => {
                    changeTheme();
                  }}
                >
                  {theme == 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
                </IconButton>
              </div>
              <div
                id="lang-btn"
                className={
                  location.pathname === '/login' || location.pathname === '/account-recovery'
                    ? 'lang-btn cursor-pointer login'
                    : 'lang-btn cursor-pointer'
                }
              >
                <IconButton
                  title={translationMessages[locale]?.langauge}
                  className={language}
                  onClick={() => {
                    changeLang();
                  }}
                >
                  <LanguageIcon />
                </IconButton>
              </div>
            </div>
          )}
          <Switch>
            <Route>
              <AdminPanelContextProvider locale={locale} messages={translationMessages[locale]}>
                <Switch>
                  <AdminRoute exact path={ROUTES.Chat}>
                    <Layout element={<ChatNew />} rightSidebar={true}  >
                    </Layout>
                  </AdminRoute>
                  <AdminRoute exact path={ROUTES.profile}>
                    <Layout element={<Profile locale={locale} messages={translationMessages[locale]} />} rightSidebar={false}  >
                    </Layout>

                  </AdminRoute>
                  <AdminRoute exact path={ROUTES.newsLetter}>
                    <Layout  element={<NewsLetter locale={locale} messages={translationMessages[locale]} />}>
                    </Layout>
                  </AdminRoute>
                  <AdminRoute exact path="/newsletters/:id/NewsLetterDetail/:articleId">
                    <Layout  element={<NewsLetterDetail locale={locale} messages={translationMessages[locale]} />}>
                    </Layout>
                  </AdminRoute>
                    <AdminRoute exact path={ROUTES.users}>
                      <Layout element={<Users locale={locale} messages={translationMessages[locale]} />}></Layout>
                    </AdminRoute>
                  <AdminRoute exact path="/newsletters/:id/NewsLetterDetail/:articleId/show/:detailsId">
                    <Layout  element={<NewsLetterArticle locale={locale} messages={translationMessages[locale]} />}>
                    </Layout>
                  </AdminRoute>

                  <AdminRoute exact path={ROUTES.scraping}>
                    <Layout  element={<Scraping locale={locale} messages={translationMessages[locale]} />}>
                    </Layout>
                  </AdminRoute>
                  <AdminRoute exact path={ROUTES.search}>
                    <Layout element={<Search locale={locale} messages={translationMessages[locale]} />}>
                    </Layout>
                  </AdminRoute>
                  <AdminRoute exact path={ROUTES.visualisation}>
                    <Layout element={<Visualisation locale={locale} messages={translationMessages[locale]} /> }>
                    </Layout>
                  </AdminRoute>
                  <AdminRoute exact path={ROUTES.newNewsLetter}>
                    <Layout element={<NewNewsLetter locale={locale} messages={translationMessages[locale]} />}>
                    </Layout>
                  </AdminRoute>
                  <Route exact path={ROUTES.login}>
                    <AdminLogin locale={locale} messages={translationMessages[locale]} />
                  </Route>

                  <Route exact path={ROUTES.forgetPassword}>
                    <AdminForgetPassword locale={locale} messages={translationMessages[locale]} />
                  </Route>

                  <Route exact path={ROUTES.passwordReset}>
                    <AdminPasswordReset locale={locale} messages={translationMessages[locale]} />
                  </Route>
                  <AdminRoute exact path={ROUTES.search}>
                    <Search locale={locale} messages={translationMessages[locale]} />
                  </AdminRoute>


                  {/*<AdminRoute exact path={ROUTES.Chat}>*/}
                  {/*  <ChatLLM locale={locale} messages={translationMessages[locale]} />*/}
                  {/*</AdminRoute>*/}

                  <AdminRoute exact path={ROUTES.Pdf}>
                    <ChatFiles locale={locale} messages={translationMessages[locale]} />
                  </AdminRoute>

                  <AdminRoute exact path={ROUTES.Online}>
                    <ChatRAGOnline locale={locale} messages={translationMessages[locale]} />
                  </AdminRoute>

                  <AdminRoute exact path={ROUTES.Offline}>
                    <ChatRAGOffline locale={locale} messages={translationMessages[locale]} />
                  </AdminRoute>

                  <AdminRoute exact path={ROUTES.SummarizerA}>
                    <ChatBrief7B locale={locale} messages={translationMessages[locale]} />
                  </AdminRoute>

                  <AdminRoute exact path={ROUTES.SummarizerB}>
                    <ChatBrief13B locale={locale} messages={translationMessages[locale]} />
                  </AdminRoute>

                  {/*<AdminRoute exact path={ROUTES.radar}>*/}
                  {/*  <Radar />*/}
                  {/*</AdminRoute>*/}



                  <AdminRoute exact path={ROUTES.newsLetter}>
                    <NewsLetter locale={locale} messages={translationMessages[locale]} />
                  </AdminRoute>
                  <AdminRoute exact path={ROUTES.scraping}>
                    <Scraping  locale={locale} messages={translationMessages[locale]} />
                  </AdminRoute>

                  <AdminRoute exact path={ROUTES.unsubscribeNewsletter}>
                    <UnsubscribeNewsletter locale={locale} messages={translationMessages[locale]} />
                  </AdminRoute>




                  <AdminRoute exact path={ROUTES.layout}>
                    <Layout locale={locale} messages={translationMessages[locale]} />
                  </AdminRoute>
                  <AdminRoute exact path={ROUTES.newNewsLetter}>
                    <NewNewsLetter locale={locale} messages={translationMessages[locale]} />
                  </AdminRoute>
                  <Route exact path={ROUTES.home}>
                    {/* <Landing locale={locale} messages={translationMessages[locale]} /> */}
                    <Redirect to={ROUTES.search} />
                  </Route>
                  {/*<AdminRoute exact path={ROUTES.layout}>*/}
                  {/*  <Layout locale={locale} messages={translationMessages[locale]} />*/}
                  {/*</AdminRoute>*/}

                  <AdminRoute exact path={ROUTES.CreateNewsLetter}>
                    <CreateNewsLetter locale={locale} messages={translationMessages[locale]} />
                  </AdminRoute>
                  <Route>
                    <NotFound locale={locale} messages={translationMessages[locale]} />
                  </Route>

                </Switch>
              </AdminPanelContextProvider>
            </Route>
          </Switch>
        </div>
      </App>

      {/*<IntlProvider locale={DEFAULT_LOCALE} messages={translationMessages[DEFAULT_LOCALE]}>*/}
      <IntlProvider locale={locale} messages={translationMessages[locale]}>
        <Route>
          <NotFound locale={locale} messages={translationMessages[locale]} />
        </Route>
      </IntlProvider>
    </Switch>
  );
};
