// @ts-nocheck
import { Redirect, Route, RouteProps } from 'react-router';
import { useLocation } from 'react-router-dom'

import { ROUTES } from '../../../../routes/app.constants';
import { useAdminPanelContext } from '../../adminPanel/adminPanelContext';

export const AdminRoute = ({ children, ...props }: RouteProps) => {
  const location = useLocation();
  const { user, isLoading } = useAdminPanelContext();

  return (
    <Route
      {...props}
      render={({ location }) =>
        isLoading ? null : (typeof user?.token != 'undefined' && user.token !== '' && location.pathname !== ROUTES.login) ? (
          children
        ) : (
          // <div></div>
          <Redirect to={{ pathname: ROUTES.login, state: { from: location } }} />
        )
      }
    />
  );
};
