// ButtonComponent.js

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/joy/Button';

const ButtonComponent = ({ file, tocError, openSummary, messages, index }) => (
  <Button
    style={{background:'transparent'}}
    disabled={false} // This makes the button always clickable
    onClick={() => openSummary(file.name, index)}
    className={file.toc == null || tocError ? 'text-link' : 'text-link'}
    title={tocError ? "Retry fetching TOC" : "Show Summary"}
    sx={{color:'rgba(135, 142, 165, 0.75)'}}
  >
    {tocError ? <p>Retry</p> : messages.Summary}
    {file.toc == null && !tocError ? <CircularProgress color="success" /> : ''}
  </Button>
);

export default ButtonComponent;
