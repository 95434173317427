import { useEffect, useRef, useState, useCallback } from 'react';
import ChatBoxInput from "../../layout/chatBoxInput";
import ChatResponse from "../../layout/chatResponse";
import * as React from "react";
import {styled} from "@mui/material/styles";
import axiosBackendApi from "../../../shared/services/api/axiosBackendInstance";
import { DEFAULT_LOCALE, translationMessages } from '../../../i18n';
import {useLocation} from "react-router-dom";

const CHAT_URL = process.env.REACT_APP_CHAT_API;
const SEARCH_URL = process.env.REACT_APP_WEB_API;
export const ChatNew = () => {
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));
  const location = useLocation();
  const [locale, setLocale] = useState('en');

    return (
    <div className='chatPage'>
      <DrawerHeader />
      <ChatBoxInput messages={translationMessages[locale]}/>
      <ChatResponse />
    </div>
  );
};
