import { useState, useEffect } from "react";

export default function useRerenderInterval(interval: number) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      setCount((count) => count + 1);
    }, interval);

    return () => clearInterval(id);
  }, [interval]);

  return count;
}
