export const white = '#ffffff';
export const black = '#000000';

export const border = '#80809B';
export const darkBorder = '#373737';
export const disabled = '#DDDDE0';
export const primary = '#085950';
export const secondary = '#2249f9';
export const error = '#C01212';
export const brightError = '#F40101';
export const warning = '#FA8C16';

// export const codGray = '#151d26';
export const codGray = '#090c10';
export const codGray2 = '#181818';
export const codGrayOpacity = 'rgba(17, 17, 17, 0)';
export const white20 = 'rgba(255, 255, 255, 0.2)';
export const boulder = '#767676';
export const mineShaft = '#333333';
export const scorpion = '#595959';
export const silver = '#C2C2C2';
export const tundora = '#444444';
// export const screaminGreen = '#055b50';
export const screaminGreen = '#95daf6';
// export const schoolBusYellow = '#b7a008';
export const schoolBusYellow = '#95DAF6';
export const mineShaft2 = '#222222';
export const dustyGray = '#999999';
export const brightGray = '#EAEAEA';

// export const gradient = `linear-gradient(255deg,${screaminGreen} -56.33%,rgba(66,242,114,0) 124.09%),${schoolBusYellow}`;
export const gradient = `linear-gradient(255deg,${screaminGreen} -56.33%,#085950 124.09%),${schoolBusYellow}`;
export const shadowGradientBottom = `linear-gradient(-180deg, ${codGrayOpacity} 0, ${codGray} 100%);`;
export const shadowGradientTop = `linear-gradient(180deg, ${codGray} 0, ${codGrayOpacity} 100%);`;
