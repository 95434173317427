export function ThumbsDownOutlined() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_907_21523)">
        <path
          d="M9.91678 1.16602V7.58268M12.8334 5.71602V3.03268C12.8334 2.37929 12.8334 2.05259 12.7063 1.80303C12.5944 1.5835 12.416 1.40503 12.1964 1.29317C11.9469 1.16602 11.6202 1.16602 10.9668 1.16602H4.73562C3.88309 1.16602 3.45682 1.16602 3.11253 1.32202C2.80909 1.45951 2.5512 1.68076 2.36917 1.95977C2.16263 2.27633 2.09781 2.69764 1.96818 3.54025L1.66305 5.52359C1.49207 6.63495 1.40658 7.19063 1.5715 7.623C1.71625 8.0025 1.98849 8.31984 2.34157 8.52061C2.74384 8.74935 3.30606 8.74935 4.43049 8.74935H4.90011C5.22681 8.74935 5.39016 8.74935 5.51494 8.81293C5.6247 8.86885 5.71394 8.95809 5.76987 9.06785C5.83345 9.19264 5.83345 9.35598 5.83345 9.68268V11.3943C5.83345 12.1887 6.47744 12.8327 7.27185 12.8327C7.46133 12.8327 7.63304 12.7211 7.71 12.5479L9.67044 8.13695C9.7596 7.93633 9.80419 7.83601 9.87466 7.76246C9.93695 7.69744 10.0135 7.64773 10.0982 7.61721C10.194 7.58268 10.3038 7.58268 10.5233 7.58268H10.9668C11.6202 7.58268 11.9469 7.58268 12.1964 7.45552C12.416 7.34367 12.5944 7.16519 12.7063 6.94567C12.8334 6.69611 12.8334 6.36941 12.8334 5.71602Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_907_21523">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ThumbsDownSolid() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_907_21523)">
        <path
          d="M9.91678 1.16602V7.58268M12.8334 5.71602V3.03268C12.8334 2.37929 12.8334 2.05259 12.7063 1.80303C12.5944 1.5835 12.416 1.40503 12.1964 1.29317C11.9469 1.16602 11.6202 1.16602 10.9668 1.16602H4.73562C3.88309 1.16602 3.45682 1.16602 3.11253 1.32202C2.80909 1.45951 2.5512 1.68076 2.36917 1.95977C2.16263 2.27633 2.09781 2.69764 1.96818 3.54025L1.66305 5.52359C1.49207 6.63495 1.40658 7.19063 1.5715 7.623C1.71625 8.0025 1.98849 8.31984 2.34157 8.52061C2.74384 8.74935 3.30606 8.74935 4.43049 8.74935H4.90011C5.22681 8.74935 5.39016 8.74935 5.51494 8.81293C5.6247 8.86885 5.71394 8.95809 5.76987 9.06785C5.83345 9.19264 5.83345 9.35598 5.83345 9.68268V11.3943C5.83345 12.1887 6.47744 12.8327 7.27185 12.8327C7.46133 12.8327 7.63304 12.7211 7.71 12.5479L9.67044 8.13695C9.7596 7.93633 9.80419 7.83601 9.87466 7.76246C9.93695 7.69744 10.0135 7.64773 10.0982 7.61721C10.194 7.58268 10.3038 7.58268 10.5233 7.58268H10.9668C11.6202 7.58268 11.9469 7.58268 12.1964 7.45552C12.416 7.34367 12.5944 7.16519 12.7063 6.94567C12.8334 6.69611 12.8334 6.36941 12.8334 5.71602Z"
          stroke="none"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_907_21523">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
